/* reset */

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
button {
  margin: 0;
  padding: 0;
}

fieldset,
img {
  border: 0 none;
}

dl,
ul,
ol,
menu,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

input,
select,
textarea,
button {
  vertical-align: middle;
}

input::-ms-clear {
  display: none;
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}

body {
  background: #fff;
  line-height: 1.5;
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #1b2027;
  min-width: 320px;
}

a {
  color: #333;
  text-decoration: none;
}

a:active {
  background-color: transparent;
}

address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}

body,
input,
button,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, NotoSansKR, 'Noto Sans KR', NotoSansJP, 'Noto Sans JP', 'Meiryo', -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
}

html[lang="ja"] body,
html[lang="ja"] input,
html[lang="ja"] button,
html[lang="ja"] textarea,
html[lang="jp"] body,
html[lang="jp"] input,
html[lang="jp"] button,
html[lang="jp"] textarea,
html[lang="ja-JP"] body,
html[lang="ja-JP"] input,
html[lang="ja-JP"] button,
html[lang="ja-JP"] textarea {
  font-family: Inter, NotoSansJP, 'Noto Sans JP', 'Meiryo', -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  word-break: break-all;
}

body.ReactModal__Body--open {
  overflow: hidden;
}
